import 'patient/styles/skeleton.css';
import PropTypes from 'prop-types';
import React from 'react';

const SkeletonComponent = ({ shape, width, height, className }) => {
  let classCss = '';
  let widthCss = '';
  classCss += `${shape}`;

  if (width === 'full') {
    widthCss = '100%';
  } else {
    widthCss = `${width}px`;
  }

  return (
    <div
      className={`skeleton-content ${classCss}`}
      style={{ width: `${widthCss}`, height: `${height}px` }}
    />
  );
};

SkeletonComponent.propTypes = {
  shape: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SkeletonComponent.defaultProps = {
  shape: 'rectangle',
  className: '',
  width: '48',
  height: '24',
};

export default SkeletonComponent;
