import 'patient/styles/video-call.css';
import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getMedicalRecordCategoryState,
  getParticipantPlatformState,
  getPatientAppointmentState,
  getPatientMedicalRecordsState,
  getVideoCallGetTokenState,
} from 'shared/redux/src/StatesGetter';
import TimerComponent from 'shared/components/TimerComponent';
import { patientAddMedicalRecordRequest } from 'shared/redux/actions/PatientProfileActions';
import FileUploadComponent from 'patient/components/FileUploadComponent';
import SelectDocumentComponent from 'patient/components/SelectDocumentComponent';
import Utils from 'shared/modules/Utils';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { usePageVisibility } from 'react-page-visibility';
import {
  RemoteUser,
  LocalVideoTrack,
  useRemoteUsers,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useJoin,
  useRTCClient,
  RemoteAudioTrack,
  RemoteVideoTrack,
  useRemoteAudioTracks,
  useRemoteVideoTracks,
  useAutoPlayAudioTrack,
} from 'agora-rtc-react';
import config from 'shared/agora/config';
import { videoCallGetTokenRequest } from '../../../shared/redux/actions/VideoCallActions';
import {
  appointmentLocalStepsRequest,
  appointmentShareMedicalRecordRequest,
} from '../../../shared/redux/actions/PatientAppointmentActions';
import { appointmentEndConsultationRequest } from '../../../shared/redux/actions/AppointmentActions';
import Alerts from '../../../shared/components/Alerts';
import { getEnv } from '../../../shared/services/EnvService';
import { getParticipantPlatformRequest } from '../../../shared/redux/actions/ParticipantPlatform';

const PatientVideoCall = () => {
  const dispatch = useDispatch();
  const isVisible = usePageVisibility();
  const { t } = useTranslation();

  const {
    id: appointmentId,
    status: appointmentStatus,
    doctor,
    patient,
    endTime,
    sharedFiles,
    enableVideo,
    enableAudio,
    isInitialTime = true,
    type: appointmentType,
    showTimeBubble = false,
    isOtherUserConnected = false,
    otherVideoEnabled = true,
    otherAudioEnabled = true,
    isCameraEnabled = true,
  } = useSelector(getPatientAppointmentState, shallowEqual);

  const { firstName, lastName, pictureMedia } = patient;

  const timeUp = () => dispatch(appointmentLocalStepsRequest({ isInitialTime: false }));
  const onTimeAboutToEnd = (val) => {
    if (val !== showTimeBubble) {
      dispatch(appointmentLocalStepsRequest({ showTimeBubble: val }));
    }
  };

  const {
    firstName: doctorFirstName,
    lastName: doctorLastName,
    name: doctorName,
    imgUrl: doctorPicture,
    specialization: doctorSpecialization,
  } = doctor;

  const medicalRecords = useSelector(getPatientMedicalRecordsState, shallowEqual);
  const participantPlatform = useSelector(getParticipantPlatformState, shallowEqual);
  const fileTypes = useSelector(getMedicalRecordCategoryState, shallowEqual);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [modalDisplayed, setModalDisplayed] = useState(false);
  const closeModal = () => setModalDisplayed(false);
  const showModal = () => setModalDisplayed(true);
  const [newUploadedFile, setNewUploadedFile] = useState('');

  const submitNewFiles = (name, type, investigationDate, pickedFiles) => {
    setNewUploadedFile(name);
    dispatch(
      patientAddMedicalRecordRequest({
        userId: patient.id,
        data: {
          title: name,
          // appointmentId,
          medicalRecordCategory: type,
          investigationDate: dayjs(investigationDate).format('YYYY-MM-DD 00:00:00'),
          filesToCreate: pickedFiles.map((file) => {
            return {
              originalName: file.originalName,
              mimeType: file.mimeType,
              size: file.size,
            };
          }),
        },
        files: pickedFiles.map((file) => {
          return file.fileData;
        }),
      }),
    );
  };

  const doSendDocument = () => {
    if (selectedFiles?.length) {
      const requestFiles: Record<string, string>[] = [];
      const filesBucket: Record<string, string>[] = [];
      [...selectedFiles, ...sharedFiles]?.map((fileId) => {
        if (filesBucket.indexOf(fileId) === -1) {
          requestFiles.push({ patientMedicalRecord: fileId });
        }
        filesBucket.push(fileId);
        return true;
      });
      dispatch(
        appointmentShareMedicalRecordRequest({
          appointmentId,
          body: {
            appointmentPatientMedicalRecords: requestFiles,
          },
        }),
      );
      dispatch(appointmentLocalStepsRequest({ sharedFiles: [...selectedFiles, ...sharedFiles] }));
    }
    setSelectedFiles([]);
    closeModal();
  };

  useEffect(() => {
    if (newUploadedFile !== '') {
      const newUploadedFileRecord = medicalRecords.filter((record) => {
        return record.title === newUploadedFile;
      });

      const newFiles: string[] = [...selectedFiles];
      const index = newFiles.indexOf(newUploadedFileRecord[0].id);
      if (index > -1) {
        newFiles.splice(index, 1);
      } else {
        newFiles.push(newUploadedFileRecord[0].id);
      }

      setTimeout(() => {
        const requestFiles: Record<string, string>[] = [];
        const filesBucket: Record<string, string>[] = [];
        [...selectedFiles, ...sharedFiles, newUploadedFileRecord[0].id]?.map((fileId) => {
          if (filesBucket.indexOf(fileId) === -1) {
            requestFiles.push({ patientMedicalRecord: fileId });
          }
          filesBucket.push(fileId);
          return true;
        });
        dispatch(
          appointmentShareMedicalRecordRequest({
            appointmentId,
            body: {
              appointmentPatientMedicalRecords: requestFiles,
            },
          }),
        );
        dispatch(
          appointmentLocalStepsRequest({
            sharedFiles: [...selectedFiles, ...sharedFiles, newUploadedFileRecord[0].id],
          }),
        );
        closeModal();
        setSelectedFiles([]);
      }, 500);
    }
  }, [medicalRecords]);

  const renderInfoBubbles = () => {
    return (
      <div className="info-message-container">
        {showTimeBubble && isInitialTime && (
          <div className="info-message minutes-left">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />

            <div className="messages">
              <div className="text">
                {`${t('lastMinutes', {
                  consultation:
                    ['free_talk', 'freeTalk'].indexOf(appointmentType) === -1
                      ? t('consultation')
                      : t('call'),
                })}`}
              </div>
            </div>
          </div>
        )}
        {(!enableVideo || !enableAudio) && (
          <div className="info-message">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />
            <div className="messages">
              {!enableAudio && <div className="text">{t('micOff')}</div>}
              {!enableVideo && <div className="text">{t('videoOff')}</div>}
            </div>
          </div>
        )}
        {(!otherVideoEnabled || !otherAudioEnabled || !isOtherUserConnected) && (
          <div className="info-message">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />
            <div className="messages">
              {!isOtherUserConnected ? (
                <div className="text">{t('doctorIsNotConnected')}</div>
              ) : (
                <>
                  {!otherVideoEnabled && <div className="text">{t('doctorVideoOff')}</div>}
                  {!otherAudioEnabled && <div className="text">{t('doctorMicOff')}</div>}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  let isFirefox = false;
  const sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf('Firefox') > -1 || sUsrAg.indexOf('FxiOS') > -1) {
    if (participantPlatform.platform === 'android') {
      isFirefox = true;
    }
  }

  //* ******* AGORA ********************************************************************
  const videoCallInfo = useSelector(getVideoCallGetTokenState, shallowEqual);
  const { room: chanel, token, user, userId } = videoCallInfo;
  // @ts-ignore
  const agoraAppId: string = getEnv('AGORA_APP_ID');
  useJoin({
    appid: agoraAppId,
    channel: chanel,
    token,
    uid: userId,
  });
  const { localMicrophoneTrack } = useLocalMicrophoneTrack();
  const { localCameraTrack } = useLocalCameraTrack();
  usePublish([localMicrophoneTrack, localCameraTrack]);
  const rtcClient = useRTCClient();
  const remoteUsers = useRemoteUsers();
  const remoteUser = remoteUsers[0];
  localMicrophoneTrack?.setMuted(!enableAudio);
  localCameraTrack?.setEnabled(enableVideo);

  useRemoteAudioTracks(remoteUsers);
  useRemoteVideoTracks(remoteUsers);

  useEffect(() => {
    dispatch(videoCallGetTokenRequest({ appointmentId }));
  }, [appointmentId]);

  const remoteUserHasVideo = remoteUser?.hasVideo;
  const remoteUserHasAudio = remoteUser?.hasAudio;
  useEffect(() => {
    dispatch(appointmentLocalStepsRequest({ otherAudioEnabled: remoteUserHasAudio }));
    dispatch(appointmentLocalStepsRequest({ otherVideoEnabled: remoteUserHasVideo }));
    if (remoteUser === undefined) {
      dispatch(appointmentLocalStepsRequest({ isOtherUserConnected: false }));
    } else {
      dispatch(appointmentLocalStepsRequest({ isOtherUserConnected: true }));
    }
    dispatch(getParticipantPlatformRequest({}));
  }, [remoteUser, remoteUserHasVideo, remoteUserHasAudio]);

  const endVideoCall = () => {
    Alerts.okCancelAlert(t('warning'), t('validations.endCall'), () => {
      localStorage.setItem('Answer_Call', `false`);

      localCameraTrack?.setEnabled(false);
      localCameraTrack?.stop();
      localCameraTrack?.close();
      localMicrophoneTrack?.setEnabled(false);
      localMicrophoneTrack?.stop();
      localMicrophoneTrack?.close();
      rtcClient.leave().then(() => {
        console.log('client leaves channel');
      });

      dispatch(
        appointmentLocalStepsRequest({
          status: 'needReview',
          callStatus: 'terminated',
          stickyStatus: 'ended',
          enableVideo: true,
          enableAudio: true,
          isInitialTime: true,
        }),
      );

      dispatch(appointmentEndConsultationRequest({ appointmentId, withNavigate: true }));
    });
  };

  const handleMic = () => {
    dispatch(appointmentLocalStepsRequest({ enableAudio: !enableAudio }));
  };

  const handleCamera = () => {
    dispatch(
      appointmentLocalStepsRequest({
        enableVideo: !enableVideo,
        isCameraEnabled: !isCameraEnabled,
      }),
    );
  };
  useEffect(() => {
    if (isVisible) {
      if (isCameraEnabled) {
        dispatch(appointmentLocalStepsRequest({ enableVideo: true }));
      }
    } else {
      dispatch(appointmentLocalStepsRequest({ enableVideo: false }));
    }
  }, [isVisible]);

  useEffect(() => {
    if (localMicrophoneTrack !== null) {
      if (enableAudio) {
        localMicrophoneTrack?.setEnabled(true).then(() => {
          // @ts-ignore
          rtcClient.publish(localMicrophoneTrack).then(() => {
            console.log(
              '%c mic - publish success',
              'color:#FF6A39; font-family:monospace; font-size: 15px;',
            );
          });
        });
      } else {
        localMicrophoneTrack?.setEnabled(false);
      }
    }

    if (localCameraTrack !== null) {
      if (enableVideo) {
        localCameraTrack?.setEnabled(true).then(() => {
          // @ts-ignore
          rtcClient.publish(localCameraTrack).then(() => {
            console.log('publish success');
          });
        });
      } else {
        localCameraTrack?.setEnabled(false);
      }
    }
  }, [localMicrophoneTrack, localCameraTrack, enableAudio, enableVideo]);

  useEffect(() => {
    dispatch(getParticipantPlatformRequest({}));
    return () => {
      localCameraTrack?.close();
      localMicrophoneTrack?.close();
      rtcClient.leave().then(() => {
        console.log('client leaves channel');
      });
    };
  }, []);
  //* END ******* AGORA **************************************************************

  return (
    <section className="video-call">
      {/* <AgoraVideoProvider options={connectionOptions} onError={() => {}}> */}
      <div className="header-top">
        <div className="medic-info">
          <div
            className="avatar"
            style={
              doctorPicture
                ? { backgroundImage: `url(${doctorPicture})` }
                : { backgroundColor: '#E5E7E8' }
            }
          >
            {!doctorPicture && (
              <div className="image-placeholder">{`${doctorFirstName?.charAt(
                0,
              )}${doctorLastName?.charAt(0)}`}</div>
            )}
          </div>
          <div className="info">
            <div className="name">{doctorName}</div>
            <div className="specialization">{doctorSpecialization}</div>
          </div>
        </div>

        <div className="time-left">
          {isInitialTime ? (
            <>
              {t('remainingTime')}
              <TimerComponent
                action={timeUp}
                endTime={endTime}
                onTimeAboutToEnd={onTimeAboutToEnd}
              />
            </>
          ) : (
            'Timpul a fost prelungit'
          )}
        </div>
      </div>
      <div className="video-container">
        <div className="medic-view-wrapper">
          <div className={`mwp ${isFirefox ? 'rotatedmwp' : ''}`}>
            {/* Render remote users' video and audio tracks */}
            <RemoteUser user={remoteUser} className="remoteUserComponent" />
            <RemoteAudioTrack track={remoteUser?.audioTrack} play />
            {remoteUser?.hasVideo ? (
              <div className={`vid ${isFirefox ? 'rotatedvid' : ''}`}>
                <RemoteUser
                  user={remoteUser}
                  playVideo
                  className={`${isFirefox ? 'rotated' : ''}`}
                />
                {/* <RemoteVideoTrack track={remoteUser?.videoTrack} play /> */}
              </div>
            ) : (
              <>
                {doctorPicture && (
                  <div
                    className="avatar-picture"
                    style={{ backgroundImage: `url(${doctorPicture})` }}
                  />
                )}
                {!doctorPicture && (
                  <div className="agora-user-text">{`${doctorFirstName?.charAt(0) ?? ''}${
                    doctorLastName?.charAt(0) ?? ''
                  }`}</div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="patient-view-wrapper">
          {/* Render the local video track */}
          <div className="vid">
            <>
              {enableVideo ? (
                <LocalVideoTrack track={localCameraTrack} play />
              ) : (
                <>
                  {pictureMedia && (
                    <div
                      className="avatar-picture"
                      style={{ backgroundImage: `url(${pictureMedia})` }}
                    />
                  )}
                  {!pictureMedia && (
                    <div className="twilio-user-text">{`${firstName?.charAt(0)}${lastName?.charAt(
                      0,
                    )}`}</div>
                  )}
                </>
              )}
            </>
          </div>
        </div>
        {renderInfoBubbles()}
      </div>
      {/* Render Controls */}
      <div className="video-actions">
        <div className="close" onClick={endVideoCall}>
          <div className="circle">
            <CustomIcon className="custom-icon" color={Colors.white} size="28" icon="Call_end" />
          </div>
          <div className="title">{t('close')}</div>
        </div>
        <div className="medical-folder" onClick={showModal}>
          <div className="circle">
            <CustomIcon className="custom-icon" color={Colors.darkGrey} size="28" icon="Dosar" />
          </div>
          <div className="title">{t('records')}</div>
        </div>
        <div className={enableAudio ? 'microphone' : 'microphone inactive'} onClick={handleMic}>
          <div className="circle">
            <CustomIcon
              className="custom-icon"
              color={enableAudio ? Colors.darkGrey : Colors.white}
              size="28"
              icon={enableAudio ? 'Microphone' : 'Microphone_off'}
            />
          </div>
          <div className="title">{t('microphone')}</div>
        </div>
        <div className={enableVideo ? 'camera' : 'camera inactive'} onClick={handleCamera}>
          <div className="circle">
            <CustomIcon
              className="custom-icon"
              color={enableVideo ? Colors.darkGrey : Colors.white}
              size="28"
              icon={enableVideo ? 'Video' : 'Video_off'}
            />
          </div>
          <div className="title">{t('camera')}</div>
        </div>
      </div>
      <Modal
        show={modalDisplayed}
        onHide={closeModal}
        animation
        centered
        className="modal-medical-documents"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('documents')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scroll-list">
            <FileUploadComponent
              onFileSubmitted={submitNewFiles}
              fileTypes={fileTypes}
              onDelete={() => {}}
            />
            <SelectDocumentComponent
              medicalRecords={Utils.makeSectionListFormat(medicalRecords, 'categoryName')}
              sharedFiles={sharedFiles}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="orange-button"
            onClick={doSendDocument}
            disabled={!selectedFiles.length}
          >
            {t('documentsShared')}
          </button>
        </Modal.Footer>
      </Modal>
      {/* </AgoraVideoProvider> */}
    </section>
  );
};

export default PatientVideoCall;
