import 'doctor/styles/final-consultation.css';
import { Nav } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getDoctorAppointmentDetailsState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import { useTranslation } from 'react-i18next';
import DoctorConsultationDetailsTabProfile from 'doctor/components/consultationDetails/DoctorConsultationDetailsTabProfile';
import DoctorConsultationDetailsTabFolder from 'doctor/components/consultationDetails/DoctorConsultationDetailsTabFolder';
import AppointmentConclusion from 'doctor/screens/appointment/AppointmentConclusion';
import { getAppointmentDetailsRequest } from 'shared/redux/actions/DoctorAppointmentsActions';
import { useEffect } from 'react';

const DoctorFinalConsultationReviewConclusions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    serverStatus,
    paymentStatus,
    id: appointmentId,
    patient,
  } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);

  useEffect(() => {
    dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
  }, []);

  const doctor = useSelector(getUserProfileState, shallowEqual);

  return (
    <section className="final-consultation">
      <div className="title-section">
        <div className="small-title-text">{t('reviewFindingsFor')}</div>
        <h1>{`${patient?.firstName} ${patient?.lastName}`}</h1>
      </div>
      <div className="small-container">
        <Tab.Container id="final-consultation-tabs-menu" defaultActiveKey="conclusions">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="profile">{t('profile')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="records">{t('records')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="conclusions">{t('conclusions')}</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="profile">
              <DoctorConsultationDetailsTabProfile />
            </Tab.Pane>
            <Tab.Pane eventKey="records">
              <DoctorConsultationDetailsTabFolder template="withoutConclusions" />
            </Tab.Pane>
            <Tab.Pane eventKey="conclusions">
              <AppointmentConclusion
                appointmentId={appointmentId}
                // conclusion={conclusion}
                serverStatus={serverStatus}
                appointmentStep="reviewConclusion"
                doctor={doctor}
                paymentStatus={paymentStatus}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </section>
  );
};

export default DoctorFinalConsultationReviewConclusions;
