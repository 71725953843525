import 'patient/styles/share-social.css';
import React, { useState } from 'react';
import CustomIcon from 'shared/components/CustomIcon';
import { Modal } from 'react-bootstrap';
import Colors from 'shared/themes/Colors';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
  XIcon,
} from 'react-share';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const ShareOnSocial = ({ doctor, profilePage }) => {
  const { t } = useTranslation();
  const [socialModalDisplayed, setSocialModalDisplayed] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const showSocialModal = () => setSocialModalDisplayed(true);
  const hideModal = () => {
    setLinkCopied(false);
    setSocialModalDisplayed(false);
  };
  const { id, fullNameWithTitle, pictureMedia, averageRating, countRating } = doctor;
  const url = `${window.location.protocol}//${window.location.host}/patient/medicProfile/${id}`;
  const iconsSize = 48;

  const urlFullName = fullNameWithTitle.replace(' ', '%20');
  const urlLink = url.replace(':', '%3A').replace('/', '%2F');
  const whatsappLink = `https://api.whatsapp.com/send?text=${urlFullName}%0A${urlLink}`;
  const d =
    'https://api.whatsapp.com/send?text=Dr.%20Cip%20Doc2%20http%3A%2F%2Flocalhost%3A3000%2Fpatient%2FmedicProfile%2F1959&app_absent=0';

  return (
    <>
      {profilePage ? (
        <button type="button" className="share" onClick={showSocialModal}>
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Share" />
          {!isMobile && <div className="text">{t('share')}</div>}
        </button>
      ) : (
        <div className="share-btn" onClick={showSocialModal}>
          <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Share" />
        </div>
      )}

      <Modal
        show={socialModalDisplayed}
        onHide={hideModal}
        animation
        centered
        className="modal-share-social"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('share')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="medic-box">
            <div className="avatar" style={{ backgroundImage: `url(${pictureMedia})` }} />
            <div className="info">
              <div className="name">{fullNameWithTitle}</div>
              <div className="rating">
                <CustomIcon
                  className="custom-icon"
                  color={Colors.mediumGrey}
                  size="16"
                  icon="Rating_2"
                />
                <i className="rating-icon" />
                {t('note')} {doctor.averageRating} ({doctor.countRating} {t('noOfReviews')})
              </div>
            </div>
          </div>

          <div className="link-text">{url}</div>

          <button
            type="button"
            className="white-button"
            onClick={() => {
              setLinkCopied(true);
              return navigator.clipboard.writeText(url);
            }}
          >
            {linkCopied ? t('linkCopied') : t('copyLink')}
          </button>
        </Modal.Body>

        <Modal.Footer>
          <div className="social-list">
            <FacebookShareButton url={url} onShareWindowClose={hideModal}>
              <FacebookIcon size={iconsSize} />
            </FacebookShareButton>
            <TwitterShareButton title={fullNameWithTitle} url={url} onShareWindowClose={hideModal}>
              <XIcon size={iconsSize} round />
            </TwitterShareButton>
            <FacebookMessengerShareButton
              title={fullNameWithTitle}
              redirectUri={url}
              url={url}
              onShareWindowClose={hideModal}
              appId="380344953549489"
            >
              <FacebookMessengerIcon size={iconsSize} />
            </FacebookMessengerShareButton>
            <LinkedinShareButton title={fullNameWithTitle} url={url} onShareWindowClose={hideModal}>
              <LinkedinIcon size={iconsSize} />
            </LinkedinShareButton>
            <ViberShareButton title={fullNameWithTitle} url={url} onShareWindowClose={hideModal}>
              <ViberIcon size={iconsSize} />
            </ViberShareButton>
            <TelegramShareButton title={fullNameWithTitle} url={url} onShareWindowClose={hideModal}>
              <TelegramIcon size={iconsSize} />
            </TelegramShareButton>
            <a href={whatsappLink} target="_blank" rel="noreferrer">
              <WhatsappIcon size={iconsSize} />
            </a>
            <EmailShareButton subject={fullNameWithTitle} url={url} onShareWindowClose={hideModal}>
              <EmailIcon size={iconsSize} />
            </EmailShareButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShareOnSocial;
